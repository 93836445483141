import {CONTROL_APPEARANCE, CONTROL_DECORATOR_CONTROL_HEIGHTS, ControlDecorator, Textarea} from "@odm/ui";
import {MdOutlineComment} from "react-icons/md";
import i18n from "../core/constants/i18n";
import {useFormikContext} from "formik";
import "./RideOrderCommentFormSection.scss";

const RideOrderCommentFormSection = ({autoFocus}) => {
    const {values,handleChange, isSubmitting} = useFormikContext();
    const maxTextLength = 9999;
    return (
        <div className={"ride-order-comment-form-section"}>
            <ControlDecorator icon={<MdOutlineComment/>}
                              controlHeight={CONTROL_DECORATOR_CONTROL_HEIGHTS.auto}
            >
                    <Textarea value={values.comment}
                              id="textarea_comment"
                              maxLength={maxTextLength}
                              name={"comment"}
                              onChange={handleChange}
                              placeholder={i18n.t("comment")}
                              autoFocus={autoFocus}
                              disabled={isSubmitting}
                              controlAppearance={CONTROL_APPEARANCE.filled}
                    />
                <div className={"comment-character-count"}>
                    {i18n.t("characters left")} {maxTextLength-values.comment.length}
                </div>
            </ControlDecorator>
        </div>
    )
}
export default RideOrderCommentFormSection;