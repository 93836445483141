import {Field, getIn, useFormikContext} from "formik";
import {Checkbox, ControlDecorator} from "@odm/ui";
import i18n from "../core/constants/i18n";
import React from "react";
import { MdAccountBalanceWallet } from "react-icons/md";


const ExemptExtraPaymentFormSection =()=>{
    const {values} = useFormikContext();

    return (
        <ControlDecorator icon={<MdAccountBalanceWallet/>}>
            <Field as={Checkbox} name={"extraPaymentExempt"}
                   id="checkbox_exemptExtraPayment"
                   label={i18n.t("extraPaymentExempt")}
                   checked={getIn(values,"extraPaymentExempt")}
            />
        </ControlDecorator>
    )
}

export default ExemptExtraPaymentFormSection;