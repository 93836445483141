import {ControlDecorator, FormikSelect} from "@odm/ui";
import { ImManWoman } from "react-icons/im";
import i18n from "../core/constants/i18n";
import {Field, getIn, useFormikContext} from "formik";
import React from "react";


const GenderFormSection=()=>{

    const {values}=useFormikContext()
    const options=[
        {value:null,label:i18n.t("select gender")},
        {value:"MALE",label: i18n.t("gender male")},
        {value:"FEMALE",label: i18n.t("gender female")}
    ]

    const value = getIn(values, "gender");
    let selectedOption;

    if (value) {
        selectedOption = options.find(opt => opt.value === value);
    }


    return(
        <ControlDecorator
            icon={<ImManWoman/>}
            label={i18n.t("gender")}
        >
            <Field id="select_gender" as={FormikSelect} name={"gender"} options={options} defaultValue={selectedOption || options[0]}/>
        </ControlDecorator>
    )
}

export default GenderFormSection;