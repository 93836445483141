import {Entity} from "./Entity";

export const FORM_BLOCKS = {
    degreeOfCare: "DEGREE_OF_CARE",
    dateOfBirth: "DATE_OF_BIRTH",
    infection: "INFECTION",
    payerInformation: "PAYER_INFORMATION",
    insuranceNumber: "INSURANCE_NUMBER",
    oxygenRequired: "OXYGEN_REQUIRED",
    carryingAidRequired: "CARRYING_AID_REQUIRED",
    overweight: "OVERWEIGHT",
    placeSpecifierComment: "PLACE_SPECIFIER_COMMENT",
    extraPaymentExempt: "EXTRA_PAYMENT",
    gender:"GENDER",
    placeOfResidence: "PLACE_OF_RESIDENCE"
}

class PageInformation extends Entity {

    static selectId = () => null;
    static schema = {
        primaryColor:           [String, { writable: true }],
        secondaryColor:         [String, { writable: true }],
        heading:                [String, { writable: true }],
        subtitle:               [String, { writable: true }],
        privacyPolicy:          [String, { writable: true }],
        imprint:                [String, { writable: true }],
        heroImageUrl:           [String, { writable: true }],
        logoImageUrl:           [String, { writable: true }],
        additionalFormBlocks:   [Object, { writable: true }],
    };

    static formValues() {
        const initFormBlockMap = Object.values(FORM_BLOCKS).reduce((map, value) => {
            map[value] = false;
            return map;
        }, {})

        return ({
            primaryColor: "",
            secondaryColor: "",
            heading: "",
            subtitle: "",
            privacyPolicy: "",
            imprint: "",
            heroImageUrl: null,
            logoImageUrl: null,
            additionalFormBlocks: initFormBlockMap,
        })
    }

    constructor(data={}) {
        super(data);
    }
}

export default Entity.applySchema(PageInformation);