import React, {Fragment, useContext, useEffect, useState, useRef} from "react";
import HeroHeader from "../components/HeroHeader";
import {
  BUTTON_EMPHASIS,
  ContentContainer,
  EmptyState,
  INLINE_MESSAGE_VARIANTS,
  InlineMessage,
  PageSection,
  SectionError,
  TransitionContainer,
  TRANSITION_CONTAINER_ANIMATIONS,
  IconButton, Button, confirm
} from "@odm/ui";
import { toast } from "react-toastify";
import RideOrderTable from "../components/RideOrderTable";
import {ThemeContext} from "../components/ThemeContextProvider";
import RideOrder from "../core/models/RideOrder";
import RideOrderForm from "../forms/RideOrderForm";
import {wsBaseURL} from "../core/http/http";
import {useDispatch, useSelector} from "react-redux";
import {localUserSelector} from "../core/redux/slices/localUserSlice";
import {Client} from '@stomp/stompjs';
import {
  getRideOrder,
  getRideOrders,
  selectFinishedSortDescending,
  selectOutwardSortAscending,
  selectReturnSortAscending,
  selectRejected, cancelRejected
} from "../core/redux/slices/rideOrdersSlice";
import {selectRideProviderById} from "../core/redux/slices/rideProviderSlice";
import useDispatchAsync from "../core/hooks/useDispatchAsync";
import RideOrderEditDialog from "../components/RideOrderEditDialog";
import {isNil} from "lodash";
import RideOrderCancelDialog from "../components/RideOrderCancelDialog";
import RideOrderReturnRideDialog from "../components/RideOrderReturnRideDialog";
import i18n from "../core/constants/i18n";
import Footer from "../components/Footer";
import {Navigate} from "react-router";
import {adminRideProvider, adminRideProviders, routeParameter} from "../core/constants/routes";
import {generatePath} from "react-router-dom";
import {getActiveAccessToken} from "../auth/KeycloakAuthProvider";
import {getRideOrderer, selectRideOrdererById} from "../core/redux/slices/rideOrdererSlice";
import {MdDone, MdExpandMore, MdExpandLess, MdClearAll} from "react-icons/md";
import {CiViewTable} from "react-icons/ci";
import {selectRideTypes} from "../core/redux/slices/rideTypeSlice";
import {Helmet} from "react-helmet";
import CautionWrapper from "../components/CautionWrapper";
import PushNotificationPrompt from "../components/PushNotificationPrompt";
import RideOrderActionPreventionDialog from "../components/RideOrderActionPreventionDialog";
import {RideOrderState} from "../core/models/RideOrderState";

const modals = {
  edit: "edit",
  cancel: "cancel",
  notCancellable:"notCancellable",
  notEditable:"notEditable",
  returnRide: "returnRide"
}

let stompClient;
const ROW_LIMIT_AMOUNT = 10;
const RideOrdersPage = () => {
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch();
  const [dispatchGetRideOrders, error] = useDispatchAsync(getRideOrders)
  const [dispatchGetRideOrderer] = useDispatchAsync(getRideOrderer)
  const [dispatchGetRideOrder] = useDispatchAsync(getRideOrder);
  const [dispatchCancelRejected] = useDispatchAsync(cancelRejected);

  const rideTypes = useSelector(selectRideTypes)

  const localUser = useSelector(localUserSelector);
  const {rideProviderId, rideOrdererId} = localUser;
  const rideProvider = useSelector((state) => selectRideProviderById(state, rideProviderId));
  const rideOrdersFinished = useSelector(selectFinishedSortDescending);
  const rideOrdersOutward = useSelector(selectOutwardSortAscending);
  const rideOrdersReturn = useSelector(selectReturnSortAscending);
  const rideOrdersRejected = useSelector(selectRejected);
  const rideOrderer = useSelector((state) => selectRideOrdererById(state, rideOrdererId))

  const timeoutRef = useRef(null);
  const tableRowRefs = useRef({});
  const [scrolledToRowId, setScrolledToRowId] = useState(null);

  const [showsOutwardRideTable, showOutwardRideTable] = useState(true);
  const [showsReturnRideTable, showReturnRideTable] = useState(true);

  const [currentRideOrder, setCurrentRideOrder] = useState(null);
  const [referencedRideOrder, setReferencedRideOrder] = useState(null);
  const [referencedOrderLoading, setReferencedOrderLoading] = useState(true);

  const [openModal, setOpenModal] = useState(null);
  const [finishedRideOrdersLimit, setFinishedRiderOrdersLimit] = useState(ROW_LIMIT_AMOUNT);

  useEffect(() => {
    if (!rideProviderId || !rideOrdererId) {
      return;
    }

    dispatchGetRideOrderer({rideOrdererId, rideProviderId})
    dispatchGetRideOrders()

    const onWsConnect = async () => {
      console.log("WebSocket: Connected");
      const accessToken = await getActiveAccessToken();

      stompClient.subscribe(`/topic/rideOrder/${rideProviderId}/${rideOrdererId}`, message => {
        const {rideOrderId} = JSON.parse(message.body);
        dispatch(getRideOrder({rideOrderId}))

      }, {"X-Auth-Token": accessToken})
    }

    const onStompError = (error) => {
      console.error("WebSocket: Stomp Error | " + JSON.stringify(error))
    }
    const onWsError = (error) => {
      console.error("WebSocket: WS Error | " + JSON.stringify(error))
    }
    const onWsDisconnect = () => {
      console.log("WebSocket: Disconnected")
    }

    const onWsBeforeConnect = async () => {
      const accessToken = await getActiveAccessToken();
      stompClient.connectHeaders = {
        "X-Auth-Token": accessToken,
      }
      console.log("WebSocket: Setting access token before connect: " + accessToken);
    }

    getActiveAccessToken().then((accessToken) => {
      stompClient = new Client({
        brokerURL: wsBaseURL,
        connectHeaders: {
          "X-Auth-Token": accessToken,
        },
        onConnect: onWsConnect,
        onStompError: onStompError,
        onWebSocketError: onWsError,
        onDisconnect: onWsDisconnect,
        beforeConnect: onWsBeforeConnect,

        //See: https://stomp-js.github.io/workaround/stompjs/rx-stomp/ng2-stompjs/2019/06/10/react-native-null-chopping-issue.html
        forceBinaryWSFrames: true,
        appendMissingNULLonIncoming: true,
        reconnectDelay: 500,
        heartbeatIncoming: 3000,
        heartbeatOutgoing: 3000,
        debug: (str) => {
          console.log("WebSocket: " + str);
        }
      });

      stompClient.activate()
    });

    return () => {
      stompClient.deactivate().catch(e => console.error(e))
    }
  }, [rideProviderId, rideOrdererId])

  const getReferencedRideOrder = async (rideOrder) => {
    setReferencedOrderLoading(true);
    try {
      if (rideOrder && rideOrder.referencedRideOrderId) {
        const fetchedRideOrder = await dispatchGetRideOrder({ rideOrderId: rideOrder.referencedRideOrderId });
        setReferencedRideOrder(fetchedRideOrder.payload);
      }
    } catch (error) {
      console.error(error);
      toast.error(i18n.t("failed to fetch referenced ride order"));
    } finally {
      setReferencedOrderLoading(false);
    }
  };
  

  const handleEdit = (rideOrder) => {
    setCurrentRideOrder(rideOrder);

    getReferencedRideOrder(rideOrder)
    if(rideProvider.isRideDateCancelable(rideOrder.dateOfRide.toDate()) || rideOrder.rideOrderState.value === RideOrderState.REJECTED){
      setOpenModal(modals.edit)
    } else {
      setOpenModal(modals.notEditable)
    }
  }

  const handleCancel = (rideOrder) => {
    setCurrentRideOrder(rideOrder);
    getReferencedRideOrder(rideOrder)
    if(rideProvider.isRideDateCancelable(rideOrder.dateOfRide.toDate()) || rideOrder.rideOrderState.value === RideOrderState.REJECTED){
      setOpenModal(modals.cancel);
    } else {
      setOpenModal(modals.notCancellable)
    }
  }

  const handleReturnRide = (rideOrder) => {
    setOpenModal(modals.returnRide)
    setCurrentRideOrder(rideOrder);
  }

  const closeModal = () => {
    setOpenModal(null)
    setCurrentRideOrder(null)
    setReferencedRideOrder(null);
  }

  const handleClearAllRejectedTransports = async () => {
    const confirmed = await confirm({
      message: i18n.t("confirm clear all rejected transports"),
      confirmLabel: i18n.t("clear all rejected transports")
    })

    if(confirmed) {
      try {
        await dispatchCancelRejected({});
      }catch(e){
        toast.error(i18n.t("failed to clear rejected transports"))
      }
    }
  }

  const imprint = rideProvider?.pageInformation?.imprint
  const privacyPolicy = rideProvider?.pageInformation?.privacyPolicy

  if (localUser.isSuperAdmin) {
    return <Navigate to={adminRideProviders} replace={true}/>
  }

  if (localUser.isAdmin) {
    const path = generatePath(adminRideProvider, {
      [routeParameter.rideProviderId]: rideProviderId,
    });

    return <Navigate to={path} replace={true}/>
  }

  return <Fragment>
    <PushNotificationPrompt/>
    <HeroHeader
        title={rideProvider?.pageInformation?.heading || theme.title}
        subtitle={rideProvider?.pageInformation?.subtitle || theme.subtitle}
        heroImage={rideProvider?.pageInformation?.heroImageUrl || theme.heroImage}
        content={<RideOrderForm rideOrder={RideOrder.fromDefaultValues({rideTypes}, rideOrderer, rideProvider)}/>}
    />
    <Helmet>
      <title>{i18n.t(rideProvider?.companyName ? "page title" : "admin page title", {provider: rideProvider?.companyName})}</title>
    </Helmet>

    <ContentContainer>
      {error ?
          <SectionError errorMessage={i18n.t("getRideOrdersByUser rejected")}/>
          :
          (<>
            {rideOrdersRejected.length > 0 ?
                <CautionWrapper title={i18n.t("ride orders rejected")}
                                message={i18n.t("ride orders rejected info")}
                                actions={<Button emphasis={BUTTON_EMPHASIS.low}
                                                 id="button_clear-all-rejected-transports"
                                                 icon={<MdClearAll/>}
                                                 title={i18n.t("clear all rejected transports")}
                                                 onClick={handleClearAllRejectedTransports}
                                />}
                >
                  <PageSection>
                    <RideOrderTable
                        tableId="table_rejected-rides"
                        timeoutRef={timeoutRef}
                        tableRowRefs={tableRowRefs}
                        scrolledToRowId={scrolledToRowId}
                        setScrolledToRowId={setScrolledToRowId}
                        rideOrders={rideOrdersRejected}
                        onEdit={handleEdit}
                        onCancel={handleCancel}
                        onAddReturnRide={handleReturnRide}
                        emptyState={null}
                    />
                  </PageSection>
                </CautionWrapper> : null}

            <PageSection
              title={i18n.t("ride orders open")}
              actionsSlot={
                <IconButton id="icon-button_show-outward-ride-table" icon={showsOutwardRideTable? <MdExpandLess/> : <MdExpandMore/>}
                onClick={() => showOutwardRideTable(!showsOutwardRideTable)}
                />
              }
            >
              <TransitionContainer show={showsOutwardRideTable} transition={[TRANSITION_CONTAINER_ANIMATIONS.height, TRANSITION_CONTAINER_ANIMATIONS.fade]}>
                <RideOrderTable
                    tableId="table_outward-rides"
                    timeoutRef={timeoutRef}
                    tableRowRefs={tableRowRefs}
                    scrolledToRowId={scrolledToRowId}
                    setScrolledToRowId={setScrolledToRowId}
                    rideOrders={rideOrdersOutward}
                    onEdit={handleEdit}
                    onCancel={handleCancel}
                    onAddReturnRide={handleReturnRide}
                    emptyState={
                      <EmptyState title={i18n.t("no orders title")}
                                  description={i18n.t("no orders description")}
                                  icon={<MdDone/>}/>

                    }
                />
              </TransitionContainer>
            </PageSection>

            

            {rideOrdersReturn.length > 0 ?
            <PageSection 
              title={i18n.t("ride orders open return")}
              actionsSlot={
                <IconButton id="icon-button_show-return-ride-table" icon={showsReturnRideTable? <MdExpandLess/> : <MdExpandMore/>}
                onClick={() => showReturnRideTable(!showsReturnRideTable)}
                />
              }
            >
              <TransitionContainer show={showsReturnRideTable} transition={[TRANSITION_CONTAINER_ANIMATIONS.height, TRANSITION_CONTAINER_ANIMATIONS.fade]}>
                <RideOrderTable
                      tableId="table_return-rides"
                      timeoutRef={timeoutRef}
                      tableRowRefs={tableRowRefs}
                      scrolledToRowId={scrolledToRowId}
                      setScrolledToRowId={setScrolledToRowId}
                      rideOrders={rideOrdersReturn}
                      onEdit={handleEdit}
                      onCancel={handleCancel}
                      onAddReturnRide={handleReturnRide}
                      emptyState={
                        <EmptyState title={i18n.t("no orders title")}
                                    description={i18n.t("no orders description")}
                                    icon={<MdDone/>}/>

                      }
                  />
              </TransitionContainer>
            </PageSection> : null}

            {rideOrdersFinished.length > 0 ?
                <PageSection title={i18n.t("ride orders finished")}
                             actionsSlot={
                               finishedRideOrdersLimit > 0
                                 ? <Button emphasis={BUTTON_EMPHASIS.low}
                                           title={i18n.t("show all")}
                                           id="button_show-all"
                                           onClick={()=>setFinishedRiderOrdersLimit(undefined)}
                                   />
                                 : <Button emphasis={BUTTON_EMPHASIS.low}
                                           title={i18n.t("show only last n results",{n: ROW_LIMIT_AMOUNT})}
                                           id="button_show-only-last-results"
                                           onClick={()=>setFinishedRiderOrdersLimit(ROW_LIMIT_AMOUNT)}
                                   />
                             }
                             messageSlot={
                               finishedRideOrdersLimit > 0
                                 ? <InlineMessage variant={INLINE_MESSAGE_VARIANTS.info}
                                                  message={i18n.t("only last n results shown",{n: ROW_LIMIT_AMOUNT})}
                                 />
                                 : null
                             }
                >
                    <RideOrderTable
                        tableId="table_finished-rides"
                        timeoutRef={timeoutRef}
                        tableRowRefs={tableRowRefs}
                        scrolledToRowId={scrolledToRowId}
                        setScrolledToRowId={setScrolledToRowId}
                        rideOrders={rideOrdersFinished.slice(0,finishedRideOrdersLimit)}
                        onEdit={handleEdit}
                        onCancel={handleCancel}
                        onAddReturnRide={handleReturnRide}
                        emptyState={
                          <EmptyState title={i18n.t("no finished orders title")}
                                      description={i18n.t("no finished orders description")}
                                      icon={<CiViewTable/>}/>

                        }
                    />
                </PageSection> : null}
          </>)
      }
    </ContentContainer>
    <RideOrderEditDialog
        rideOrder={currentRideOrder}
        referencedRideOrder={referencedRideOrder}
        isLoading={referencedOrderLoading}
        isOpen={openModal === modals.edit && !isNil(currentRideOrder)}
        onClose={closeModal}
    />
    <RideOrderCancelDialog
        rideOrder={currentRideOrder}
        referencedRideOrder={referencedRideOrder}
        isLoading={referencedOrderLoading}
        isOpen={openModal === modals.cancel && !isNil(currentRideOrder)}
        onClose={closeModal}
    />
    <RideOrderActionPreventionDialog
      rideProvider={rideProvider}
      isOpen={openModal === modals.notCancellable && !isNil(currentRideOrder)}
      onClose={closeModal}
      titleText={i18n.t("ride order cannot be canceled title")}
      descriptionText={i18n.t("ride order cannot be canceled description")}
    />
    <RideOrderActionPreventionDialog
      rideProvider={rideProvider}
      isOpen={openModal === modals.notEditable && !isNil(currentRideOrder)}
      onClose={closeModal}
      titleText={i18n.t("ride order cannot be edited title")}
      descriptionText={i18n.t("ride order cannot be edited description")}
    />
    <RideOrderReturnRideDialog
        rideOrder={currentRideOrder}
        isOpen={openModal === modals.returnRide && !isNil(currentRideOrder)}
        onClose={closeModal}
    />
    <Footer imprint={imprint} privacyPolicy={privacyPolicy}/>
  </Fragment>
}

export default RideOrdersPage;