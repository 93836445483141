import {
  Calendar,
  InlineError,
  HeadlineText,
  ControlDecorator,
  Input,
  SegmentedButton,
  DynamicRow, DynamicGrid, DynamicRowElement
} from "@odm/ui";
import i18n from "../core/constants/i18n";
import {Field, getIn, useFormikContext} from "formik";
import { DateTime } from "../core/models/DateTime";
import RideOrder from "../core/models/RideOrder";
import moment from "moment/moment";
import "./RideOrderTimingFormSection.scss";

const RideOrderTimingFormSection = ({ rideProvider, rideOrderer, rideOrder }) => {
  const { values, setFieldValue, errors } = useFormikContext();

  const getMinDate = () => {
    if (!rideOrder?.isNew) return new Date();

    let minDate = moment();

    while (!rideProvider.isRideDateWithinOrderLimit(minDate)) {
      minDate.add(1, "day");
    }
    return minDate.toDate();
  };


  let pickupTimeTypeAllowed = rideProvider?.pickupTimeTypeAllowed;
  let arrivalTimeTypeAllowed = rideProvider?.arrivalTimeTypeAllowed;

  if (rideOrderer?.pickupTimeTypeAllowed || rideOrderer?.arrivalTimeTypeAllowed) {
    pickupTimeTypeAllowed = rideOrderer.pickupTimeTypeAllowed;
    arrivalTimeTypeAllowed = rideOrderer.arrivalTimeTypeAllowed;
  }

  return (
    <DynamicGrid numCols={2}>
      <DynamicRow>
        {pickupTimeTypeAllowed && arrivalTimeTypeAllowed ? (
          <SegmentedButton items={[
            { label: i18n.t("pickup at"), value: false },
            { label: i18n.t("arrival at"), value: true },
          ]}
             id="button-toggle_pickup-arrival"
             onChange={(e) => setFieldValue("isArrivalTime", e)}
             value={[getIn(values,"isArrivalTime")]}
             multiple={false}
             compareFn={(item, value)=> item === value}
          />
        ): (
        <HeadlineText className="ride-order-timing-headline" element="p">
            {values.isArrivalTime ? i18n.t("arrival at"): i18n.t("pickup at")}
        </HeadlineText>
        )}
        <ControlDecorator>
          <Field as={Input}
                 type={"time"}
                 value={values.timeOfRide.toInputString()}
                 onChange={(e) =>
                  setFieldValue("timeOfRide", new DateTime(e.target.value, {formatString: "HH:mm"}))
                 }
          />
        </ControlDecorator>
      </DynamicRow>
      <DynamicRow>
        <DynamicRowElement colSpan={2}>
          <Calendar
            value={values.dateOfRide.toDate()}
            minDate={getMinDate()}
            onChange={(e) =>
              setFieldValue("dateOfRide", new DateTime(e, RideOrder.schema.dateOfRide[1]))
            }
          />
          <InlineError errorMessage={errors.timeOfRide} />
        </DynamicRowElement>
      </DynamicRow>
    </DynamicGrid>
  );
};

export default RideOrderTimingFormSection;
