import RideOrderTypeFormSection from "./RideOrderTypeFormSection";
import RideOrderPassengerFormSection from "./RideOrderPassengerFormSection";
import RideOrderRouteFormSection from "./RideOrderRouteFormSection";
import {
    IconContainer,
    TransitionContainer,
    TRANSITION_CONTAINER_ANIMATIONS,
    ControlDecorator,
    CONTROL_DECORATOR_CONTROL_HEIGHTS,
    Textarea,
    CONTROL_APPEARANCE,
    SectionMessage,
} from "@odm/ui";
import { MdExpandMore, MdExpandLess, MdOutlineCalendarToday } from "react-icons/md";
import RideOrderCommentFormSection from "./RideOrderCommentFormSection";
import { useSelector } from "react-redux";
import { selectRideTypes } from "../core/redux/slices/rideTypeSlice";
import { useState } from "react";
import { useFormikContext } from "formik";
import "./RideOrderDialogFormSection.scss";
import RideOrderTimingFormSection from "./RideOrderTimingFormSection";
import i18n from "../core/constants/i18n";
import { AiFillEdit } from "react-icons/ai";
import { localUserSelector } from "../core/redux/slices/localUserSlice";
import { selectRideOrdererById } from "../core/redux/slices/rideOrdererSlice";
import { selectRideProviderById } from "../core/redux/slices/rideProviderSlice";
import { FORM_BLOCKS } from "../core/models/PageInformation";
import { DateTime } from "../core/models/DateTime";
import DateOfBirthFormSection from "./DateOfBirthFormSection";
import InsuranceNumberFormSection from "./InsuranceNumberFormSection";
import PayerFormSection from "./PayerFormSection";
import DegreeOfCareFormSection from "./DegreeOfCareFormSection";
import InfectionFormSection from "./InfectionFormSection";
import OxygenFormSection from "./OxygenFormSection";
import CarryingAidFormSection from "./CarryingAidFormSection";
import WeightFormSection from "./WeightFormSection";
import { RideOrderType } from "../core/models/RideOrderType";
import Place from "../core/models/Place";
import ExemptExtraPaymentFormSection from "./ExemptExtraPaymentFormSection";
import GenderFormSection from "./GenderFormSection";

const RideOrderDialogFormSection = ({ rideOrder, referenceRideDateTime, showReasonForLastChange = true }) => {
    const { values, handleChange, isSubmitting } = useFormikContext();
    const rideTypes = useSelector(selectRideTypes);
    const [showCalendar, setShowCalendar] = useState(false);

    const { rideOrdererId, rideProviderId } = useSelector(localUserSelector);
    const rideOrderer = useSelector((state) => selectRideOrdererById(state, rideOrdererId));
    const rideProvider = useSelector((state) => selectRideProviderById(state, rideProviderId));

    const selectedRideType = rideTypes.find(rt => rt.number === values.rideType.number);
    const additionalFormBlocks = rideOrderer?.additionalFormBlocks || rideProvider?.pageInformation?.additionalFormBlocks || {};
    const referenceMessage = rideOrder?.rideOrderType === RideOrderType.OUTWARD ? "editReturnTransport" : "editOutwardTransport";

    let quickSelectPlace = null;
    if (rideOrderer?.placeOfBusiness) {
        quickSelectPlace = new Place({
            ...rideOrderer?.placeOfBusiness,
            placeDescription: rideOrderer?.name,
        });
    }

    return (
        <div className={"ride-order-dialog-form-section"}>
            <RideOrderTypeFormSection rideTypes={rideTypes} active={selectedRideType} />

        <hr />
        <fieldset>
            <RideOrderPassengerFormSection additionalFormBlocks={additionalFormBlocks}/>
            {additionalFormBlocks[FORM_BLOCKS.dateOfBirth] ? <DateOfBirthFormSection/> : null}
            {additionalFormBlocks[FORM_BLOCKS.insuranceNumber] ? <InsuranceNumberFormSection/> : null}
            {additionalFormBlocks[FORM_BLOCKS.payerInformation] ? <PayerFormSection/> : null}
            {additionalFormBlocks[FORM_BLOCKS.degreeOfCare] ? <DegreeOfCareFormSection/> : null}
            {additionalFormBlocks[FORM_BLOCKS.gender] ? <GenderFormSection/> : null}

            {additionalFormBlocks[FORM_BLOCKS.infection] ? <InfectionFormSection/> : null}
            {additionalFormBlocks[FORM_BLOCKS.oxygenRequired] ? <OxygenFormSection/> : null}
            {additionalFormBlocks[FORM_BLOCKS.carryingAidRequired] ? <CarryingAidFormSection/> : null}
            {additionalFormBlocks[FORM_BLOCKS.overweight] ? <WeightFormSection/> : null}
            {additionalFormBlocks[FORM_BLOCKS.extraPaymentExempt] ? <ExemptExtraPaymentFormSection/> : null}
            <RideOrderRouteFormSection additionalFormBlocks={additionalFormBlocks} placeOfBusiness={quickSelectPlace}/>

                <div>
                    <div className={"icon-section icon-section-timing"} onClick={() => setShowCalendar((show) => !show)}>
                        <IconContainer icon={<MdOutlineCalendarToday />} />
                        <small> {values.rideTimingType} {values.dateOfRide.formatRelativeDay()}, {values.timeOfRide.localizedTime}</small>
                        <IconContainer icon={showCalendar ? <MdExpandLess/> : <MdExpandMore />} />
                    </div>

                    <TransitionContainer show={showCalendar} transition={TRANSITION_CONTAINER_ANIMATIONS.height} className={"timing-section"}>
                        <RideOrderTimingFormSection rideProvider={rideProvider} rideOrderer={rideOrderer} rideOrder={rideOrder} />
                    </TransitionContainer>

                    {!isNaN(referenceRideDateTime) && (
                            <SectionMessage
                                appearance="normal"
                                variant="warning"
                                title={i18n.t("alert")}
                                message={i18n.t(referenceMessage, {
                                    date: `${i18n.t("dateAt")} ${new DateTime(referenceRideDateTime).localizedDate} ${i18n.t("timeAt")} ${new DateTime(referenceRideDateTime).localizedTime}`
                                })}
                            />
                    )}
                </div>

                <div className={"ride-order-text-area-form-section"}>
                    <RideOrderCommentFormSection />
                    {showReasonForLastChange && (
                        <ControlDecorator icon={<AiFillEdit />} controlHeight={CONTROL_DECORATOR_CONTROL_HEIGHTS.auto}>
                            <Textarea
                                value={values.reasonForLastChange}
                                id="textarea_reason-for-last-change"
                                name={"reasonForLastChange"}
                                onChange={handleChange}
                                placeholder={i18n.t("reasonForLastChange")}
                                disabled={isSubmitting}
                                controlAppearance={CONTROL_APPEARANCE.filled}
                            />
                        </ControlDecorator>
                    )}
                </div>
            </fieldset>
        </div>
    );
};

export default RideOrderDialogFormSection;
