import { cloneDeep } from "lodash";
class Place {

    static keys = {
        city: "city",
        zip: "zip",
        street: "street",
        country: "country",
        placeDescription: "placeDescription",
    }

    static fromPlaceDetails(place){
        if(!place?.address_components) return

        let address = {};
        let street = [];
        const placeId = place.place_id;
        const placeDescription = place.name;

        for (const component of place.address_components) {
            const componentType = component.types[0];

            switch (componentType) {
                case "street_number": {
                    street[1] = component.long_name;
                    break;
                }
                case "route": {
                    street[0] = component.long_name;
                    break;
                }
                case "postal_code": {
                    address.zip = component.long_name;
                    break;
                }
                case "locality":
                    address.city = component.long_name;
                    break;
                case "country":
                    address.country = component.long_name;
                    break;
            }
        }

        const {geometry:{location:{lat,lng}}} = place;

        const latitude = lat();
        const longitude = lng();

        address.street = street.join(' ');
        return new Place({
            address,
            placeId,
            placeDescription,
            coordinates:{
                latitude,
                longitude
            }
        })
    }

    constructor(place ={}){
        if(place !== null){
            const {address, coordinates, placeDescription,placeComment, placeId} = place
            if(address) { this.address = {...this.address, ...address}; }
            if(coordinates) {this.coordinates = {...this.coordinates, ...coordinates}}
            this.placeComment = placeComment ?? "";
            this.placeDescription = placeDescription ?? "";
            this.placeId = placeId ?? "";
        }

    }

    address = {
        city: "",
        zip: "",
        street: "",
        country: ""
    }

    coordinates = {
        longitude: 0,
        latitude: 0
    }

    get description(){return this.placeDescription}

    get city() { return this.address.city }
    set city(city) {
        this.address.city = city;
        this.clearPlaceDetails();
    }

    get zip() { return this.address.zip }
    set zip(zip) {
        this.address.zip = zip;
        this.clearPlaceDetails();
    }

    get street() { return this.address.street }
    set street(street) {
        this.address.street = street;
        this.clearPlaceDetails();
    }

    get country() { return this.address.country }
    set country(country) { this.address.country = country }

    get inlineAddress() {
        return [this.street, [this.zip, this.city].filter(Boolean).join(' ')].filter(Boolean).join(", ");
    }

    getBounds(radius){
        if(!this.coordinates.latitude || !this.coordinates.longitude) return;

        const radiusShift = radius / 100;

        return {
            north: this.coordinates.latitude + radiusShift,
            south: this.coordinates.latitude - radiusShift,
            east: this.coordinates.longitude + radiusShift,
            west: this.coordinates.longitude - radiusShift
        }
    }
    clearPlaceDetails() {
        this.placeId = null;
        this.placeDescription = null;
        this.coordinates = {
            longitude: 0,
            latitude: 0
        }
    }

    isEmpty(){
        return [this.placeDescription,this.placeId,...Object.values(this.address)].find((v)=>!!v)
    }

    valueOf(){
        return cloneDeep(this)
    }
}

export default Place;