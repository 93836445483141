import "./RideOrderForm.scss";
import i18n from "../core/constants/i18n";
import {
  Button,
  BUTTON_EMPHASIS,
  BUTTON_VARIANTS, DynamicGrid,
  DynamicRow,
  IconContainer,
  InlineError, Modal,
  Spinner, TRANSITION_CONTAINER_ANIMATIONS, TransitionContainer
} from "@odm/ui";
import {Formik} from "formik";
import {order} from "../core/redux/slices/rideOrdersSlice";
import {useSelector} from "react-redux";
import {selectRideTypes} from "../core/redux/slices/rideTypeSlice";
import RideOrderPassengerFormSection from "./RideOrderPassengerFormSection";
import RideOrderRouteFormSection from "./RideOrderRouteFormSection";
import classNames from "classnames";
import {useState} from "react";
import RideOrderCommentFormSection from "./RideOrderCommentFormSection";
import ExemptExtraPaymentFormSection from "./ExemptExtraPaymentFormSection"
import RideOrderTimingFormSection from "./RideOrderTimingFormSection";
import RideOrderTypeFormSection from "./RideOrderTypeFormSection";
import RideOrderInfectionFormSection from "./RideOrderInfectionFormSection";
import useDispatchAsync from "../core/hooks/useDispatchAsync";
import {VALIDATION_SCHEMATA} from "../core/constants/validationSchemata";
import RideOrder from "../core/models/RideOrder";
import {pick} from "lodash";
import {localUserSelector} from "../core/redux/slices/localUserSlice";
import {selectRideOrdererById} from "../core/redux/slices/rideOrdererSlice";
import {selectRideProviderById} from "../core/redux/slices/rideProviderSlice";
import moment from "moment";
import {DateTime} from "../core/models/DateTime";
import {toast} from "react-toastify";
import FormHeading from "../components/FormHeading";
import CmRideType from "../core/models/CmRideType";
import {GoChevronDown, GoChevronRight} from "react-icons/go";
import {FaClock} from "react-icons/fa";
import Place from "../core/models/Place";
import {FORM_BLOCKS} from "../core/models/PageInformation";
import DateOfBirthFormSection from "./DateOfBirthFormSection";
import DegreeOfCareFormSection from "./DegreeOfCareFormSection";
import InfectionFormSection from "./InfectionFormSection";
import OxygenFormSection from "./OxygenFormSection";
import CarryingAidFormSection from "./CarryingAidFormSection";
import InsuranceNumberFormSection from "./InsuranceNumberFormSection";
import PayerFormSection from "./PayerFormSection";
import WeightFormSection from "./WeightFormSection";
import GenderFormSection from "./GenderFormSection";

const modals = {
  rideOrderTypeDialog: "rideOrderTypeDialog",
  rideOrderTimingDialog: "rideOrderTimingDialog",
  rideOrderCommentDialog: "rideOrderCommentDialog",
}

const RideOrderForm = ({rideOrder}) => {
  const [dispatchOrder, orderError] = useDispatchAsync(order)

  const {rideOrdererId, rideProviderId} = useSelector(localUserSelector)
  const rideOrderer = useSelector((state) => selectRideOrdererById(state, rideOrdererId))
  const rideTypes = useSelector(selectRideTypes)
  const rideProvider = useSelector((state) => selectRideProviderById(state, rideProviderId));
  const additionalFormBlocks = rideOrderer?.additionalFormBlocks || rideProvider?.pageInformation?.additionalFormBlocks || {};

  const [activeModal, setActiveModal] = useState(null);
  const [showsDetails, showDetails] = useState(false);

  if (!rideProvider || !rideTypes || !rideOrderer) {
    return <Spinner/>
  }

  if (rideTypes.length <= 0) {
    return <InlineError errorMessage={i18n.t("no ride types error")}/>
  }

  const submitOrder = (values, formik) => {

    const rideOrder = new RideOrder(values);
    let payload = rideOrder.payload();
    Object.keys(payload).forEach(key => {
      const value = payload[key];
      if (value instanceof DateTime && isNaN(value.toDate())) {
        payload[key] = undefined;
      }
    });

    payload = JSON.parse(JSON.stringify(payload));

    const defaultOrder = RideOrder.fromDefaultValues({rideTypes}, rideOrderer, rideProvider);
    const defaultValues = JSON.parse(JSON.stringify(defaultOrder.formValues()));

    return dispatchOrder(payload).then(() => {
      formik.resetForm(defaultValues);
      formik.setFieldValue("dateOfBirth", ""); //to also clear text within input
      showDetails(false);
      toast.success(i18n.t("create order successful"));
    }).catch((e) => {
      toast.error(i18n.t("order rejected"));
      console.error(e);
    });
  }

  const resetTypeDialog = ({setValues, values}) => {
    const resetValues = pick(rideOrder.formValues(), ["infected", "infectedComment", "rideType"])

    setValues({...values, ...resetValues})
  }

  const getDefaultRideTime = () => {
    let defaultRideTime = moment().add(1,"day");


    while (!rideProvider.isRideDateWithinOrderLimit(defaultRideTime)){
      defaultRideTime.add(1, 'day');
    }

    if(!rideProvider.isDateWithinBusinessHours(defaultRideTime)){
      defaultRideTime = rideProvider.getClosestBusinessHour(defaultRideTime)
    }


    return {
      timeOfRide: new DateTime(defaultRideTime.toDate(), RideOrder.schema.timeOfRide[1]),
      dateOfRide: new DateTime(defaultRideTime.toDate(), RideOrder.schema.dateOfRide[1])
    };
  }

  const resetTimingDialog = ({setValues, values}) => {

    if(rideOrder.isNew) {
      setValues({...values,...getDefaultRideTime(), isArrivalTime: pick(rideOrder.formValues(), "isArrivalTime")})
    }else{
      const resetValues = pick(rideOrder.formValues(), ["isArrivalTime", "timeOfRide", "dateOfRide"])
      setValues({...values, ...resetValues})
    }
  }

  const resetCommentDialog = ({setValues, values}) => {
    const resetValues = pick(rideOrder.formValues(), ["comment"])

    setValues({...values, ...resetValues})
  }

  let initialValues = rideOrder.formValues();

  if (rideOrder.isNew) {
    initialValues = {...initialValues,...getDefaultRideTime()}
  }

  return (
      <div className={"ride-order-form"}>
        <Formik
            initialValues={initialValues}
            onSubmit={submitOrder}
            validateOnChange={true}
            validationSchema={VALIDATION_SCHEMATA.dynamicRideOrder({
              additionalFormBlocks,
              rideProvider,
              rideOrder
            })}>

          {(formik) => {

            const {
              values,
              handleSubmit,
              isSubmitting,
              isValid,
              errors,
              dirty
            } = formik

            const activeRideType = new CmRideType(values.rideType);

            let quickSelectPlace = null;
            if (rideOrderer?.placeOfBusiness) {
              quickSelectPlace = new Place({
                ...rideOrderer?.placeOfBusiness,
                placeDescription: rideOrderer?.name,
              });

              initialValues.startLocation = quickSelectPlace
            }

            return <>
              <form id="order-form" onSubmit={handleSubmit} className={classNames({"submitting": isSubmitting})}>
                <DynamicGrid numCols={2}>
                  <DynamicRow>
                    <div>
                      <FormHeading>{i18n.t("passenger")}</FormHeading>
                      <RideOrderPassengerFormSection additionalFormBlocks={additionalFormBlocks}/>
                      {additionalFormBlocks[FORM_BLOCKS.dateOfBirth] ? <DateOfBirthFormSection/> : null}
                      {additionalFormBlocks[FORM_BLOCKS.insuranceNumber] ? <InsuranceNumberFormSection/> : null}
                      {additionalFormBlocks[FORM_BLOCKS.payerInformation] ? <PayerFormSection/> : null}
                      {additionalFormBlocks[FORM_BLOCKS.degreeOfCare] ? <DegreeOfCareFormSection/> : null}
                      {additionalFormBlocks[FORM_BLOCKS.gender] ? <GenderFormSection/> : null}
                    </div>
                    <div>
                      <FormHeading>{i18n.t("rideType")}</FormHeading>
                      <RideOrderTypeFormSection rideTypes={rideTypes}
                                                active={activeRideType}
                      />
                      {additionalFormBlocks[FORM_BLOCKS.infection] ? <InfectionFormSection/> : null}
                      {additionalFormBlocks[FORM_BLOCKS.oxygenRequired] ? <OxygenFormSection/> : null}
                      {additionalFormBlocks[FORM_BLOCKS.carryingAidRequired] ? <CarryingAidFormSection/> : null}
                      {additionalFormBlocks[FORM_BLOCKS.overweight] ? <WeightFormSection/> : null}
                      {additionalFormBlocks[FORM_BLOCKS.extraPaymentExempt] ? <ExemptExtraPaymentFormSection/> : null}
                    </div>
                  </DynamicRow>

                  <DynamicRow>
                    <div>
                      <FormHeading>{i18n.t("route")}</FormHeading>
                      <RideOrderRouteFormSection placeOfBusiness={quickSelectPlace}
                                                 additionalFormBlocks={additionalFormBlocks}
                      />
                    </div>
                    <div>
                      <FormHeading>{i18n.t("dateAndTime")}</FormHeading>
                      <div className={"time-labels clickable"} id="label_date-time" onClick={() => setActiveModal(modals.rideOrderTimingDialog)}>
                        <div className={"time-icon-container"}>
                          <IconContainer icon={<FaClock/>}/>
                        </div>
                        <div className={"time-label-container"}>
                          <div className={"pickupLabel"}>{values.isArrivalTime ? i18n.t("arrival") : i18n.t("pickUp")}</div>
                          <div className={"dateTimeLabel"}>
                            {i18n.t("on date at time", {
                              date: `${values.dateOfRide.formatRelativeDay()}, ${values.dateOfRide.localizedDate}`,
                              time: values.timeOfRide.localizedTime,
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </DynamicRow>
                </DynamicGrid>

                <InlineError errorMessage={!!orderError && i18n.t("order rejected")}/>

                <Button title={i18n.t("orderNow")}
                        id="button_order-now"
                        type={"submit"}
                        loading={isSubmitting}
                        disabled={!isValid || !dirty}
                />

                <div className={"add-note-btn-container"}>
                  <Button title={i18n.t(showsDetails ? "hideNote" : "addNote")}
                          id="button_add-note"
                          onClick={() => showDetails(!showsDetails)}
                          emphasis={BUTTON_EMPHASIS.minimal}
                          icon={showsDetails ? <GoChevronDown/> : <GoChevronRight/>}
                  />
                </div>

                <TransitionContainer show={showsDetails} transition={TRANSITION_CONTAINER_ANIMATIONS.height}>
                  <RideOrderCommentFormSection autoFocus/>
                </TransitionContainer>

              </form>
              <Modal isOpen={activeModal === modals.rideOrderCommentDialog}
                     onClose={() => setActiveModal(null)}
                     actionsSlot={<>
                       <Button variant={BUTTON_VARIANTS.cancel}
                               id="button_comment-cancel"
                               title={i18n.t("cancel")}
                               emphasis={BUTTON_EMPHASIS.low}
                               onClick={() => {
                                 resetCommentDialog(formik)
                                 setActiveModal(null)
                               }}
                       />
                       <Button variant={BUTTON_VARIANTS.confirm}
                               id="button_comment-confirm"
                               title={i18n.t("confirm")}
                               onClick={() => setActiveModal(null)}
                       />
                     </>}
              >
                <RideOrderCommentFormSection autoFocus={true}/>
              </Modal>

              <Modal isOpen={activeModal === modals.rideOrderTypeDialog}
                     onClose={() => setActiveModal(null)}
                     actionsSlot={<>
                       <Button variant={BUTTON_VARIANTS.cancel}
                               id="button_type-cancel"
                               title={i18n.t("cancel")}
                               emphasis={BUTTON_EMPHASIS.low}
                               onClick={() => {
                                 resetTypeDialog(formik)
                                 setActiveModal(null)
                               }}
                       />
                       <Button variant={BUTTON_VARIANTS.confirm}
                               id="button_type-confirm"
                               title={i18n.t("confirm")}
                               onClick={() => setActiveModal(null)}
                       />
                     </>}
              >
                <RideOrderTypeFormSection rideTypes={rideTypes}
                                          active={values.rideType}
                />
                <hr/>
                <RideOrderInfectionFormSection/>
              </Modal>

              <Modal isOpen={activeModal === modals.rideOrderTimingDialog}
                     onClose={() => setActiveModal(null)}
                     title={i18n.t("dateAndTime")}
                     actionsSlot={<>
                       <Button variant={BUTTON_VARIANTS.cancel}
                               id="button_timing-cancel"
                               title={i18n.t("cancel")}
                               emphasis={BUTTON_EMPHASIS.low}
                               onClick={() => {
                                 resetTimingDialog(formik)
                                 setActiveModal(null)
                               }}
                       />
                       <Button variant={BUTTON_VARIANTS.confirm}
                               id="button_timing-confirm"
                               title={i18n.t("confirm")}
                               onClick={() => setActiveModal(null)}
                               disabled={errors.timeOfRide}
                       />
                     </>}
              >
                <RideOrderTimingFormSection rideProvider={rideProvider}
                                            rideOrderer={rideOrderer}
                                            rideOrder={rideOrder}
                />
              </Modal>
            </>
          }}
        </Formik>
      </div>
  )
}

export default RideOrderForm;