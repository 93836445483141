import Place from "./Place";
import i18n from "../constants/i18n";
import {RideOrderState} from "./RideOrderState";
import {RideOrderType} from "./RideOrderType";
import {DateTime} from "./DateTime";
import {Entity} from "./Entity";
import CmRideType from "./CmRideType";

class RideOrder extends Entity {

    static selectId = (rideOrder) => rideOrder.rideOrderId;
    static schema = {
        isArrivalTime:          [Boolean],
        comment:                [String],
        createdOn:              [Date, { writable: false, nullable: true }],
        rideProviderId:         [String, { writable: false, nullable: true }],
        dateOfRide:             [DateTime, {formatString: "yyyy-MM-dd"}],
        endLocation:            [Place],
        firstName:              [String],
        lastName:               [String],
        rideOrderId:            [String, { writable: false, nullable: true }],
        rideOrderState:         [RideOrderState, { writable: false, nullable: true }],
        rideType:               [CmRideType,{nullable: true}],
        startLocation:          [Place],
        reasonForLastChange:    [String],
        timeOfRide:             [DateTime, {formatString: "HH:mm:ss"}],
        pickupTime:             [DateTime, {formatString: "HH:mm:ss"}],
        endLocationArrivalTime: [DateTime, {formatString: "HH:mm:ss"}],
        userId:                 [String, { writable: false, nullable: true }],
        message:                [String, { writable: false}],

        // returnRide fields
        rideOrderType:          [RideOrderType, { writable: false, nullable: true }],
        outwardRideOrderId:     [String, { writable: false, nullable: true }],
        referencedRideOrderId:  [String, { writable: false, nullable: true }],

        // Dynamic fields
        dateOfBirth:            [DateTime, {formatString: "yyyy-MM-dd"}],
        insuranceNumber:        [String],
        payerInformation:       [String],
        degreeOfCare:           [Number],
        infectionInformation:   [Object],
        oxygenRequired:         [Boolean],
        carryingAidRequired:    [Boolean],
        weight:                 [Number],
        extraPaymentExempt:     [Boolean],
        gender:                 [String, {nullable: true}],
        patientAddress:       [Place]
    };

    static calculateTimingValues(rideProvider, rideOrderer, requestedIsArrivalTime) {
        let pickupTimeTypeAllowed = rideProvider?.pickupTimeTypeAllowed;
        let arrivalTimeTypeAllowed = rideProvider?.arrivalTimeTypeAllowed;
    
        if (rideOrderer?.pickupTimeTypeAllowed || rideOrderer?.arrivalTimeTypeAllowed) {
            pickupTimeTypeAllowed = rideOrderer.pickupTimeTypeAllowed;
            arrivalTimeTypeAllowed = rideOrderer.arrivalTimeTypeAllowed;
        }
    
        let isArrivalTime = requestedIsArrivalTime ? requestedIsArrivalTime : false;
    
        if (!pickupTimeTypeAllowed && arrivalTimeTypeAllowed) {
            isArrivalTime = true;
        }
    
        if (!arrivalTimeTypeAllowed && pickupTimeTypeAllowed) {
            isArrivalTime = false;
        }
    
        return isArrivalTime;
    };    
    

    static fromDefaultValues (options = {}, rideOrderer, rideProvider) {
        const {rideTypes = {}} = options;
        let defaultRideType = null;

        if (rideTypes.length > 0) {
            // "fish" for a ride type of 'sitting' kind, which should preferably be default
            const sittingRideType = rideTypes.find(rt => rt.number === 'S');
            defaultRideType = sittingRideType || rideTypes[0];
        }

        return new RideOrder({
            isArrivalTime: this.calculateTimingValues(rideProvider, rideOrderer),
            comment: "",
            dateOfRide: new Date(),
            firstName: "",
            infected: false,
            infectedComment: "",
            lastName: "",
            rideType: defaultRideType,
            startLocation: {},
            endLocation: {},
            reasonForLastChange: "",
            timeOfRide: new Date(),
            pickupTime: new Date(),
            endLocationArrivalTime: new Date(),

            // Dynamic fields
            dateOfBirth: null,
            insuranceNumber: "",
            payerInformation: "",
            degreeOfCare: null,
            infectionInformation: {
                infectious: false,
                infectionNote: "",
            },
            oxygenRequired: false,
            carryingAidRequired: false,
            weight: 0,
            extraPaymentExempt: false,
            gender:null,
            patientAddress: {}
        })
    }

    constructor(data = {}){
        super(data)
    }

    get isNew() { return !this.createdOn }

    get fullName() {return [this.firstName,this.lastName].join(" ")}

    get rideTimingType() {return this.isArrivalTime ? i18n.t("arrival") : i18n.t("pickUp");}

    get rideTimingMessage() {return `${this.rideTimingType} ${this.dateOfRide.formatRelativeDay()}, ${this.timeOfRide.localizedTime}`}

}

export default Entity.applySchema(RideOrder);