import React from "react";
import {Field} from "formik";
import {ControlDecorator, Input} from "@odm/ui";
import i18n from "../core/constants/i18n";
import {IoScaleOutline} from "react-icons/io5";

const WeightFormSection = () => {
  return <ControlDecorator icon={<IoScaleOutline/>} label={i18n.t("weight")}>
    <Field as={Input}
           type={"number"}
           id="weight-input"
           name={"weight"}
    />
  </ControlDecorator>
}

export default WeightFormSection;