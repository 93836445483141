export const IS_LOCAL_ENV = (window.location.hostname === 'localhost');
export const IS_DEV_ENV = (window.location.hostname === 'ui-dev.weborder.odm-hosting.de');
export const IS_TEST_ENV = (window.location.hostname === 'ui-test.weborder.odm-hosting.de');

const testAuth = {
    url: "https://keycloak-test.auth.odm-hosting.de/",
    realm: "order",
    clientId: "weborder-frontend",
}
const prodAuth = {
    url: "https://keycloak.auth.odm-hosting.de/",
    realm: "order",
    clientId: "weborder-frontend",
}

const baseConfig = {
    port: 8080,
    protocol: "http",
    wsProtocol: "ws",
    basePath: "/",
    wsBasePath: "/stomp",
    tokenActivityBufferMs: 5000,
}

const localConfig = {
    ...baseConfig,
    host: "localhost",
    auth: testAuth
}

const devConfig = {
    ...baseConfig,
    protocol: "https",
    wsProtocol: "wss",
    port: 443,
    host: "api-dev.weborder.odm-hosting.de",
    auth: testAuth
}

const testConfig = {
    ...baseConfig,
    protocol: "https",
    wsProtocol: "wss",
    port: 443,
    host: "api-test.weborder.odm-hosting.de",
    auth: testAuth
}

const productionConfig = {
    ...baseConfig,
    protocol: "https",
    wsProtocol: "wss",
    port: 443,
    host: "api.weborder.odm-hosting.de",
    auth: prodAuth
}

export const getConfig = () => IS_LOCAL_ENV ? localConfig : IS_TEST_ENV ? testConfig : IS_DEV_ENV ? devConfig : productionConfig;
