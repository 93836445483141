import React from 'react';
import {Button, ContentContainer, EmptyState, PageHeader} from "@odm/ui";
import {MdBlock, MdLogout} from "react-icons/md";
import { SiAdblock } from "react-icons/si";
import i18n from "../core/constants/i18n";
import { useSelector } from 'react-redux';
import { globalErrorSelector } from '../core/redux/slices/globalErrorSlice';
import {keycloak} from "../auth/KeycloakAuthProvider";

const ErrorNavigator = ({ children }) => {
  const { message } = useSelector(globalErrorSelector);

  const logout = () => keycloak.logout()

  let title, description, icon;

  switch (message) {
    case "Unauthorized":
      title = i18n.t("not authorized");
      description = i18n.t("not authorized page");
      icon = <MdBlock />;
      break;
    case "Forbidden":
      title = i18n.t("forbidden");
      description = i18n.t("forbidden description");
      icon = <SiAdblock />;
      break;
    default:
      return children;
  }

  return (
    <>
      <PageHeader title={title} />
      <ContentContainer>
        <EmptyState
          title={title}
          description={description}
          icon={icon}
          actions={<Button icon={<MdLogout/>} onClick={logout} title={i18n.t("log out")} /> }
        />
      </ContentContainer>
    </>
  );
};

export default ErrorNavigator;
