import { I18n } from 'i18n-js';

const i18n = new I18n({
    de: {
        "monday abbr": "Mo",
        "tuesday abbr": "Di",
        "wednesday abbr": "Mi",
        "thursday abbr": "Do",
        "friday abbr": "Fr",
        "saturday abbr": "Sa",
        "sunday abbr": "So",
        "error": "Fehler",
        "add time period": "Zeitraum hinzufügen",
        "remove time period": "Zeitraum entfernen",
        "days of week": "Wochentage",
        "tenant": "Mandant",
        "department": "Dienststelle",
        "ride orderers": "Besteller",
        "create": "Erstellen",
        "email/username": "E-Mail",
        "username": "Benutzername",
        "RideOrdererState ACTIVE": "aktiv",
        "RideOrdererState DISSOLVED": "gelöscht",
        "RideOrderState CANCELED": "storniert",
        "RideOrderState CHANGED": "geändert",
        "RideOrderState NEW": "neu",
        "RideOrderState COMMITTED": "übermittelt",
        "RideOrderState REJECTED": "abgelehnt",
        "RideOrderState ACCEPTED": "angenommen",
        "RideOrderState EN_ROUTE": "unterwegs",
        "RideOrderState ARRIVED_AT_PICKUP_LOCATION": "am Abholort",
        "RideOrderState PICKUP_COMPLETE": "Patient aufgenommen",
        "RideOrderState COMPLETED": "abgeschlossen",
        "RideProviderState ACTIVE": "aktiv",
        "actions": "Aktion",
        "administration": "Administration",
        "all rides": "Alle Aufträge",
        "arrival": "Ankunft",
        "cancel": "Abbrechen",
        "canceled rides": "Stornierungen",
        "comment for infection": "Anmerkung zu Infektion",
        "comment": "Bemerkung",
        "completed rides": "Abgeschlossene Aufträge",
        "confirm": "Bestätigen",
        "time before outward ride": "Zeitpunkt liegt vor der Hinfahrt",
        "return ride before outward ride": "Zugehörige Rückfahrt liegt vor der Hinfahrt",

        "ride type canceled": "%{rideType} wurde storniert",
        "ride type changed": "%{rideType} wurde geändert",
        "ride type rejected": "%{rideType} wurde abgelehnt",

        "return ride": "Rückfahrt",
        "outward ride": "Hinfahrt",
        "scroll to return ride": "Zur Rückfahrt springen",
        "scroll to outward ride": "Zur Hinfahrt springen",
    
        "confirmCancelTransport": "Wollen Sie den Transport für %{transport} wirklich stornieren?",
        "confirmCancelReturnTransport": "Dieser Transport ist mit einer Rückfahrt %{date} verbunden, bitte stornieren Sie diese bei Bedarf im Nachgang.",
        "confirmCancelOutwardTransport": "Dieser Transport ist mit einer Hinfahrt %{date} verbunden, bitte stornieren Sie diese bei Bedarf im Nachgang.",
        "editReturnTransport": "Dieser Transport ist mit einer Rückfahrt %{date} verbunden, bitte editieren Sie diese bei Bedarf im Nachgang.",
        "editOutwardTransport": "Dieser Transport ist mit einer Hinfahrt %{date} verbunden, bitte editieren Sie diese bei Bedarf im Nachgang.",
        "ride order cannot be canceled title": "Transport kann nicht storniert werden",
        "ride order cannot be edited title": "Transport kann nicht bearbeitet werden",
        "ride order cannot be canceled description": "Leider ist es nicht möglich, die Fahrt kurz vor der geplanten Durchführung zu stornieren. Bitte setzen Sie sich telefonisch mit uns in Verbindung.",
        "ride order cannot be edited description": "Leider ist es nicht möglich, die Fahrt kurz vor der geplanten Durchführung zu stornieren. Bitte setzen Sie sich telefonisch mit uns in Verbindung.",
        "copyright notice": "© 2022 opta data motion GmbH",
        "dissolve rideProvider": "Transportunternehmen löschen",
        "confirm dissolve rideProvider": 'Geben Sie bitte "%{rideProvider}" in das Eingabefeld ein, um das Transportunternehmen endgültig zu löschen.',
        "confirm dissolve user": 'Wollen Sie den Benutzer "%{user}" wirklich löschen?',
        "image upload successful": "Bild erfolgreich aktualisiert",
        "dissolve user": "Benutzer löschen",
        "dissolve rideOrderer": "Besteller löschen",
        "confirm dissolve rideOrderer": 'Wollen Sie den Besteller "%{rideOrderer}" wirklich löschen?',
        "dateAt": "am",
        "timeAt": "um",
        "arrival at": "Ankunft bis",
        "firstname": "Vorname",
        "lastname": "Nachname",
        "loading results": "Lädt Ergebnisse",
        "local user": "Benutzer",
        "log out": "Abmelden",
        "logged in as": "Angemeldet als {{name}}",
        "myTransports": "Meine Transporte",
        "no orders description": "Sobald ein neuer Auftrag erstellt wird, wird er hier angezeigt.",
        "no orders title": "Keine offenen Aufträge",
        "no finished orders description": "Abgeschlossene Aufträge werden hier angezeigt.",
        "no finished orders title": "Keine abgeschlossenen Aufträge",
        "route": "Wegstrecke",
        "no results": "Keine Ergebnisse",
        "not yet implemented description": "Diese Seite wurde noch nicht implementiert.",
        "not yet implemented title": "Noch nicht umgesetzt",
        "open rides": "Ausstehende Aufträge",
        "orderNow": "Jetzt bestellen",
        "orderTransport": "Transport bestellen",
        "passenger": "Fahrgast",
        "pickup time": "Abholzeit",
        "arrival time": "Ankunftszeit",
        "pickUp": "Abholung",
        "pickup at": "Abholung um",
        "pointInTime": "Zeitpunkt",
        "rideType": "Transportart",
        "dateAndTime": "Datum & Zeit",
        "rides": "Aufträge",
        "addNote": "Bemerkung hinzufügen",
        "hideNote": "Bemerkung ausblenden",
        "on date at time": "{{date}} um {{time}}",
        "customization": "Branding",
        "ip-list form": "IP Whitelist konfigurieren",
        "order form": "Bestellformular",
        "ip-list form description": "Durch die Beschränkung des Zugriffs auf benutzerdefinierte IPv4-Adressen können nur bestimmte Geräte oder Netzwerke von %{orderer} auf das Bestellformular  zugreifen",
        "scheduled trips form": "Die Auswahl von Abhol- und Ankunftsterminen erlauben oder beschränken",
        "custom scheduled trips form": "%{orderer} die Auswahl von Abhol- und Ankunftsterminen erlauben oder beschränken",
        "custom scheduled trips form description": "Diese Einstellungen gelten nur für %{orderer}. Eine Standard-Konfiguration für alle Besteller kann in den Seitenanpassungen definiert werden.",
        "custom scheduled trips": "Benutzerdefinierte Auswahl von Abhol- und Ankunftsterminen",
        "customize global order form description": "Zusätzlich zum Mindestumfang des Bestellformulars können weitere Formular-Blöcke hinzugezogen werden. Diese Einstellungen gelten global für alle Besteller, können jedoch für eizelne Besteller in deren Einstellungen überschrieben werden.",
        "customize local order form description": "Zusätzlich zum Mindestumfang des Bestellformulars können weitere Formular-Blöcke extra für diesen Besteller hinzugezogen werden. Diese Einstellungen gelten nur für %{orderer}. Eine Standard-Konfiguration für alle Besteller kann in den Seitenanpassungen definiert werden.",
        "degreeOfCare_none": "keiner",
        "degreeOfCare_1": "1 - geringe Beeinträchtigung",
        "degreeOfCare_2": "2 - erhebliche Beeinträchtigung",
        "degreeOfCare_3": "3 - schwere Beeinträchtigung",
        "degreeOfCare_4": "4 - schwerste Beeinträchtigung",
        "degreeOfCare_5": "5 - schwerste Beeinträchtigung mit besonderen Anforderungen",
        "infectious": "Infektionsfahrt",
        "infection note placeholder": "Art der Infektionskrankheit",
        "degreeOfCare": "Pflegegrad",
        "dateOfBirth": "Geburtsdatum",
        "infection": "Infektionsangaben",
        "overweight": "Angaben zum Gewicht des Patienten",
        "placeSpecifierComment": "Zusätzliche Adressangaben",
        "placeSpecifierCommentPlaceholder": "Zimmer / Station / Etage / Gebäude",
        "payerInformation": "Kostenträger",
        "payer placeholder": "AOK Hessen",
        "weight": "Gewicht des Patient",
        "insuranceNumber": "Versicherungsnummer",
        "oxygenRequired": "Notwendigkeit von Sauerstoff",
        "oxygenRequiredLabel": "benötigt Sauerstoff",
        "placeOfResidence": "Adresse des Fahrgastes",
        "placeOfResidenceLabel": "Wohnort",
        "carryingAidRequiredLabel": "benötigt Tragehilfe",
        "insurance number placeholder": "12123456M123",
        "carryingAidRequired": "Notwendigkeit von Tragehilfen",
        "use custom order form for orderer": "Eigenes Bestellformular für %{orderer} verwenden",
        "use ip-list": "Zugriff für %{orderer} auf benutzerdefinierte IPv4-Adressen beschränken",
        "ip address": "IPv4-Adresse",
        "ip address input placeholder": "IPv4-Adresse eingeben",
        "no ip title": "Keine IPv4-Adressen",
        "no ip description": "IPv4-Adressen, die für diese Organisation bestellen dürfen, werden hier aufgelistet.",
        "hero image": "Hintergrundbild",
        "logo image": "Logo",
        "save changes": "Speichern",
        "save": "Speichern",
        "search": "Suche",
        "select rideType": "Transportart wählen",
        "settings": "Einstellungen",
        "state": "Status",
        "tenants": "Mandanten",
        "timeSuffix": "Uhr",
        "today": "Heute",
        "tomorrow": "Morgen",
        "transportCancel": "Fahrt stornieren",
        "reverse": "stornieren",
        "transportEdit": "Fahrt bearbeiten",
        "order return ride": "Rückfahrt bestellen",
        "transportFrom": "Von",
        "transportTo": "Nach",
        "tripDestination": "Beförderungsziel",
        "tripOrigin": "Abholort",
        "users": "Benutzer",
        "phone": "Telefon",
        "email": "E-Mail",
        "yesterday": "Gestern",
        "last": "Letzten",
        "in the past": "In der Vergangenheit",
        "organisation": "Organisation",
        "license number": "Lizenznummer",
        "company name": "Firmenname",
        "address": "Adresse",
        "street and number": "Straße und Hausnummer",
        "street": "Straße",
        "zip": "Postleitzahl",
        "city": "Stadt",
        "country": "Land",
        "germany": "Deutschland",
        "austria": "Österreich",
        "invalid zip":"Postleitzahl muss aus 4-5 Ziffern bestehen",
        "orderer name": "Bezeichnung des Bestellers",
        "rideProviders": "Transportunternehmen",
        "add rideProvider": "Transportunternehmen hinzufügen",
        "restrict orders to business hours": "Bestellungen auf Geschäftszeiten beschränken",
        "business hours may not be empty": "Geschäftszeiten dürfen nicht leer sein",
        "start of business hours": "Beginn der Geschäftszeit",
        "end of business hours": "Ende der Geschäftszeit",
        "end of business hours not before start": "Das Ende der Geschäftszeiten darf nicht vor dem Beginn liegen",
        "general": "Allgemein",
        "postal address": "Anschrift",
        "contact": "Kontakt",
        "contact us by telephone number": "Sie erreichen uns unter folgender Telefonnummer:",
        "business hours": "Geschäftszeiten",
        "add ride orderer": "Besteller hinzufügen",
        "page customization": "Seiten-Anpassungen",
        "primary color": "Primärfarbe",
        "secondary color": "Sekundärfarbe",
        "push notification prompt": "Möchten Sie über wichtige Fahrt-Ereignisse automatisch benachrichtigt werden?",
        "allow notifications": "Benachrichtigungen erlauben",
        "deny notifications": "Nein, danke",
        "add user": "Benutzer hinzufügen",
        "successfully enabled notifications": "Benachrichtigungen aktiviert",
        "successfully disabled notifications": "Benachrichtigungen deaktiviert",
        "could not enable notifications": "Benachrichtigungen konnten nicht aktiviert werden",
        "enable browser notifications": "Browser Benachrichtigungen aktivieren",
        "disable browser notifications": "Browser Benachrichtigungen deaktivieren",
        "enable email notifications": "E-Mail Benachrichtigungen aktivieren",
        "disable email notifications": "E-Mail Benachrichtigungen deaktivieren",
        "enabling notifications": "wird aktiviert...",
        "reply": "Antwort",
        "invalid date": "Ungültiges Datum",
        "ride provider privacy policy": "Datenschutzerklärung",
        "ride provider imprint": "Impressum",
        "ride provider heading": "Seiten-Titel",
        "ride provider subtitle": "Untertitel",
        "version number": "Version %{version}",
        "details": "Details",
        "email admin for orderer": "max.mustermann@max-mustermann.de",
        "configure": "Einstellungen",
        "password": "Passwort",
        "name": "Name",
        "repeat password": "Passwort wiederholen",
        "administrators": "Administratoren",
        "edit user": "Benutzer bearbeiten",
        "remove user": "Benutzer löschen",
        "resend invite": "E-Mail zur Aktivierung erneut senden",
        "resend invite successful": "E-Mail zur Aktivierung erfolgreich versendet",
        "resend invite failed": "E-Mail zur Aktivierung konnte nicht versendet werden",
        "order rejected": "Bei der Bestellung Ihres Transports ist ein Fehler aufgetreten.",
        "cancelOrder rejected": "Beim Stornieren Ihrer Bestellung ist ein Fehler aufgetreten.",
        "editOrder rejected": "Beim Bearbeiten Ihrer Bestellung ist ein Fehler aufgetreten.",
        "getRideOrdersByUser rejected": "Ihre Transporte konnten nicht abgerufen werden.",
        "getRideProviders rejected": "Die Liste der Transportunternehmen konnte nicht abgerufen werden.",
        "no rideProviders": "Keine Transportunternehmen",
        "no rideProviders description": "Alle Transportunternehmen werden hier aufgelistet.",
        "no rideProviders filter match": "Keine Transportunternehmen gefunden",
        "no rideProviders filter match description": "Die angegebenen Suchkriterien ergaben keine Treffer.",
        "rideProviderAdd rejected": "Beim Anlegen des Transportunternehmens ist ein Fehler aufgetreten.",
        "rideProvider dissolve rejected": "Beim Löschen des Transportunternehmens ist ein Fehler aufgetreten.",
        "rideProvider edit rejected": "Beim Bearbeiten des Transportunternehmens ist ein Fehler aufgetreten.",
        "rideProviderGet rejected": "Die Daten dieses Transportunternehmens konnten nicht abgerufen werden.",
        "get users rejected": "Die Liste der Benutzer konnte nicht abgerufen werden.",
        "no users description": "Benutzer, die für diese Organisation bestellen dürfen, werden hier aufgelistet.",
        "no admins description": "Benutzer, die diese Organisation verwalten dürfen, werden hier aufgelistet.",
        "no users title": "Keine Benutzer",
        "no admins title": "Keine Administratoren",
        "add admin": "Administrator hinzufügen",
        "edit admin": "Administrator bearbeiten",
        "remove admin": "Administrator löschen",
        "no ride types error": "Für dieses Transportunternehmen sind keine Transportarten verfügbar.",
        "reasonForLastChange": "Grund der Änderung",
        "add orderer": "Besteller hinzufügen",
        "could not add user": "Der Benutzer konnte nicht hinzugefügt werden. Falls der Fehler besteht, wenden Sie sich an den Support.",
        "could not edit user": "Der Benutzer konnte nicht bearbeitet werden. Falls der Fehler besteht, wenden Sie sich an den Support.",
        "user duplicated email": "Ein Benutzer mit dieser email-Adresse existiert bereits.",
        "user add rejected": "Beim Anlegen des Benutzers ist ein Fehler aufgetreten.",
        "user dissolve rejected": "Beim Löschen des Benutzers ist ein Fehler aufgetreten.",
        "user edit rejected": "Beim Bearbeiten des Benutzers ist ein Fehler aufgetreten.",
        "rideOrderer edit rejected": "Beim Bearbeiten des Bestellers ist ein Fehler aufgetreten.",
        "rideOrderer dissolve rejected": "Beim Löschen des Bestellers ist ein Fehler aufgetreten.",
        "rideOrderer add rejected": "Beim Erstellen des Bestellers ist ein Fehler aufgetreten.",
        "get rideOrderers rejected": "Die Liste der Besteller konnte nicht abgerufen werden.",
        "page not found": "Die Seite konnte nicht aufgerufen werden",
        "not authorized": "Fehlende Berechtigung",
        "edit successful": "Änderungen wurden gespeichert",
        "edit failed": "Änderungen konnten nicht gespeichert werden",
        "create order successful": "Auftrag erfolgreich versendet",
        "create order failed": "Auftrag konnte nicht gespeichert werden",
        "create orderer successful": "Besteller wurde hinzugefügt",
        "dissolve orderer successful": "Besteller wurde entfernt",
        "create user successful": "Benutzer wurde hinzugefügt",
        "remove user successful": "Benutzer wurde entfernt",
        "cancel order successful": "Auftrag wurde storniert",
        "cancel return order successful": "Rückfahrt wurde storniert",
        "create ride provider successful": "Transportunternehmen erfolgreich angelegt",
        "remove ride provider successful": "Transportunternehmen erfolgreich entfernt",
        "cancel order failed": "Der Auftrag konnte nicht storniert werden",
        "cancel return order failed": "Die Rückfahrt konnte nicht storniert werden",
        "failed to fetch referenced ride order": "Die referenzierte Fahrt konnte nicht abgerufen werden.",
        "not authorized page": "Sie sind nicht dazu berechtigt, diese Seite aufzurufen.",
        "forbidden": "Zugriff verweigert",
        "forbidden description": "Sie befinden sich möglicherweise nicht im zulässigen IP-Bereich. Bitte wenden Sie sich an ihren Administrator",
        "ride time not within business hours": "Die geplante Fahrtzeit befindet sich ausserhalb der Geschäftszeiten",
        "ride time must be after outward ride": "Die geplante Rückfahrt muss nach der Hinfahrt liegen.",
        "ride time must be before return ride": "Die geplante Fahrt muss vor der Rückfahrt liegen.",
        "ride time must be in the future": "Die Fahrtzeit muss in der Zukunft liegen",
        "not existing page": "Diese Seite existiert nicht",
        "visit home page": "Besuchen Sie die Startseite",
        "field required": "Feld \"%{field}\" darf nicht leer sein",
        "field min length": "%{field} muss mindestens %{length} Zeichen lang sein",
        "invalid ip address": "Ungültige IP-Adresse",
        "duplicated ip address": "IP-Adresse bereits in der Liste",
        "invalid address": "Ungültige Adresse",
        "invalid email": "Ungültige E-Mail",
        "end of business hours must be after start": "Ende der Geschäftszeit muss nach dem Beginn liegen",
        "overview": "Übersicht",
        "filter": "Filter",
        "filter options": "Suche (z.B. nach %{possibilities})",
        "reset filter": "Suche zurücksetzen",
        "ride orders open": "Offene Transporte",
        "ride orders open return": "Offene Rückfahrten",
        "ride orders finished": "Abgeschlossene Transporte",
        "ride orders rejected": "Abgelehnte Transporte",
        "ride orders rejected info": "Diese Fahrten konnten nicht angenommen werden. Bitte planen Sie entsprechend um oder kontaktieren Sie uns telefonisch.",
        "http required": "Bitte geben Sie eine URL ein.",
        "correct url": "Bitte geben Sie eine gültige URL ein.",
        "place description": "Bezeichnung",
        "date of birth": "Geburtsdatum",
        "api key": "API Schlüssel",
        "page title": "Fahrten buchen - %{provider}",
        "admin page title": "Fahrten buchen",
        "httpImprint": 'https://domain.de/impressum',
        "form contains errors": "Formular enthält Fehler",
        "generate new api key": "Neuen API-Schlüssel generieren",
        "privacyPolicy": "Datenschutzerklärung",
        "httpPrivacyPolicy": 'https://domain.de/datenschutz',
        "file size": "Es sind maximal 5MB pro Bild möglich.",
        "pageInformation edit rejected": "Beim Bearbeiten der Seiten-Anpassungen ist ein Fehler aufgetreten.",
        "general ride provider configuration": "Allgemein",
        "custom ride provider configuration": "Individuell",
        "ride orderer configuration": "Besteller",
        "restrictions": "Fristen",
        "restrictions description": "Sie können für bestimmte Aktionen Fristen definieren, die sich am Ausführungsdatum der jeweiligen Fahrt orientieren. Bestimmen Sie die Anzahl an Tagen innerhalb Ihrer Betriebszeiten, die zwischen dem Tag der folgenden Aktionen und dem Fahrtdatum liegen sollen.",
        "prevent cancellation days":"Bearbeitung / Stornierung (Tage vor Durchführung)",
        "prevent order days":"Bestellung aufgeben (Tage vor Durchführung)",
        "ride time not within order limit":"Das gewählte Transportdatum ist zu kurzfristig",
        "alert": "Achtung",
        "show all": "Alle Anzeigen",
        "show only last n results": "Nur die letzten %{n} Ergebnisse anzeigen",
        "only last n results shown": "Es werden nur die letzten %{n} Ergebnisse angezeigt",
        "email notification enabled": "E-Mail Benachrichtigung",
        "clear all rejected transports": "Alle entfernen",
        "confirm clear all rejected transports": "Wollen Sie wirklich alle abgelehnten Transporte entfernen?",
        "failed to clear rejected transports": "Beim Entfernen der Transporte ist ein Fehler aufgetreten",
        "extraPaymentExempt": "Zuzahlungsbefreit",
        "characters left": "Übrige Zeichen",
        "gender": "Geschlecht",
        "gender male": "männlich",
        "gender female": "weiblich",
        "select gender": "Geschlecht wählen"
    },
    en: {
        "orderTransport": "Order transport"
    }
})



i18n.fallbacks = true;
i18n.defaultLocale = "de";
i18n.locale = "de";


export default i18n;
