import {Field, getIn, useFormikContext} from "formik";
import {CONTROL_APPEARANCE, ControlDecorator, Input} from "@odm/ui";
import {MdPersonOutline} from "react-icons/md";
import i18n from "../core/constants/i18n";
import {FORM_BLOCKS} from "../core/models/PageInformation";
import PlaceInput from "../components/PlaceInput";


const fieldNames = {
  firstName: "firstName",
  lastName: "lastName",
  patientAddress: "patientAddress"
}

const RideOrderPassengerFormSection = ({additionalFormBlocks}) => {
  const {isSubmitting, errors, touched} = useFormikContext()

  return (
      <>
        <ControlDecorator icon={<MdPersonOutline/>}
                          error={getIn(touched, fieldNames.firstName) && getIn(errors, fieldNames.firstName)}
        >
          <Field placeholder={i18n.t("firstname")}
                 id="input_firstname"
                 as={Input}
                 name={fieldNames.firstName}
                 disabled={isSubmitting}
                 controlAppearance={CONTROL_APPEARANCE.filled}
          />
        </ControlDecorator>
        <ControlDecorator icon={true}
                          error={getIn(touched, fieldNames.lastName) && getIn(errors, fieldNames.lastName)}
        >
          <Field placeholder={i18n.t("lastname")}
                 id="input_lastname"
                 name={fieldNames.lastName}
                 as={Input}
                 disabled={isSubmitting}
                 controlAppearance={CONTROL_APPEARANCE.filled}
          />
        </ControlDecorator>
        {additionalFormBlocks[FORM_BLOCKS.placeOfResidence]
         ? <PlaceInput prefix={fieldNames.patientAddress}
                             additionalFormBlocks={additionalFormBlocks}
                             placeholder={i18n.t("placeOfResidenceLabel")}
                             idSuffix="place-of-residence"
                             showAdditionFormBlocks={true}
                             disabled={isSubmitting}
                             icon={true}
          />
          : null
        }
      </>
  )
}

export default RideOrderPassengerFormSection