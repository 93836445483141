import React, {useState} from "react";
import http from "../core/http/http";
import RideProvider from "../core/models/RideProvider";
import {useDispatch} from "react-redux";
import rideProviderSlice from "../core/redux/slices/rideProviderSlice";
import {
  ControlDecorator, InlineError,
  Input,
  Spinner
} from "@odm/ui";
import i18n from "../core/constants/i18n";
import {maxImageUploadSizeBytes} from "../core/constants/ui";
import {toast} from "react-toastify";

const ImageUpload = ({name, rideProvider, label, id}) => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  const [errorMessage, setErrorMessage] = useState('')
  const [uploadError, setUploadError] = useState('')


  const onUpload = (event) => {
    const file = event.currentTarget.files[0]

    if(file.size > maxImageUploadSizeBytes) {
      return setErrorMessage(() => i18n.t('file size'))
    } else {
      setIsLoading(true);

      const formData = new FormData();
      formData.append('file', file);
      formData.append('rideProviderId', rideProvider.rideProviderId)
      formData.append('imagePlacement', name)

      http.post(`/api/provider/customizeImage`, formData)
        .then((response) => {
          const rideProvider = new RideProvider(response.data)
          dispatch(rideProviderSlice.actions.upsertRideProvider(rideProvider.values))
          setErrorMessage('')
          toast.success(i18n.t("image upload successful"));
        })
        .catch(error => {
          setUploadError(error);
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  return (<>
    <ControlDecorator
      label={label}
      error={errorMessage}
    >
      {
        isLoading ?
          <Spinner/> :
          <Input
            type={"file"}
            name={name}
            id={id}
            accept={'image/*'}
            onChange={(e) => onUpload(e, name)}
          />
      }
    </ControlDecorator>
    <InlineError errorMessage={uploadError && i18n.t("pageInformation edit rejected")}/>
  </>)


}

export default ImageUpload;