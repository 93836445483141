import { MdOutlineHome, MdOutlineLocationOn, MdOutlinePerson, MdOutlineTripOrigin} from "react-icons/md";
import PlaceInput from "../components/PlaceInput";
import i18n from "../core/constants/i18n";
import {getIn, useFormikContext} from "formik";
import {IconContainer} from "@odm/ui";
import {FORM_BLOCKS} from "../core/models/PageInformation";
import Place from "../core/models/Place";

const fieldNames = {
    startLocation:"startLocation",
    endLocation:"endLocation",

}

const RideOrderRouteFormSection = ({placeOfBusiness, additionalFormBlocks}) => {
    const {
        isSubmitting,
      values
    } = useFormikContext();

    const patientAddress = new Place(getIn(values,"patientAddress"))

    const quickSelectValues = [
      ...placeOfBusiness && [{
        title: placeOfBusiness.description,
        description: placeOfBusiness.inlineAddress,
        id: "place-of-business-suggestion",
        value: placeOfBusiness,
        leadingSlot: <IconContainer icon={<MdOutlineHome />} />,
      }],
      ...(additionalFormBlocks[FORM_BLOCKS.placeOfResidence] && patientAddress.isEmpty() ) ? [{
        title: patientAddress.description,
        description: patientAddress.inlineAddress,
        id: "patient-address-suggestion",
        value: patientAddress,
        leadingSlot: <IconContainer icon={<MdOutlinePerson />} />,
      }] : []
    ]

    return (
        <>
                <PlaceInput placeholder={i18n.t("tripOrigin")}
                            additionalFormBlocks={additionalFormBlocks}
                            idSuffix="trip-origin"
                            showAdditionFormBlocks={true}
                            disabled={isSubmitting}
                            quickSelectValues={quickSelectValues}
                            placeOfBusiness={placeOfBusiness}
                            icon={<MdOutlineTripOrigin/>}
                            prefix={fieldNames.startLocation}
                />
                <PlaceInput placeholder={i18n.t("tripDestination")}
                            additionalFormBlocks={additionalFormBlocks}
                            idSuffix="trip-destination"
                            showAdditionFormBlocks={true}
                            disabled={isSubmitting}
                            quickSelectValues={quickSelectValues}
                            placeOfBusiness={placeOfBusiness}
                            icon={<MdOutlineLocationOn/>}
                            prefix={fieldNames.endLocation}
                />
        </>
    )
}

export default RideOrderRouteFormSection;