import React, { Fragment } from "react";
import i18n from "../core/constants/i18n";
import {
  ButtonGroup,
  EmptyState, ICON_SIZES,
  IconButton,
  IconContainer,
  SECTION_MESSAGE_VARIANTS,
  SECTION_MESSAGE_APPEARANCES,
  SectionMessage,
  StyledTable,
  StyledTableRow,
} from "@odm/ui";
import CmRideTypeIcon from "./CmRideTypeIcon";
import { FaCarOn, FaFlagCheckered } from "react-icons/fa6";
import { MdDone, MdEdit, MdOutlineCancel, MdSupportAgent } from "react-icons/md";
import { RiArrowGoBackFill } from "react-icons/ri";
import { BsArrowUpRightCircle, BsArrowDownLeftCircle } from "react-icons/bs";
import "./RideOrderTable.scss";
import OrderStateBadge from "./OrderStateBadge";
import Warning from "./Warning";
import { RideOrderState } from "../core/models/RideOrderState";
import { RideOrderType } from "../core/models/RideOrderType";

const RideOrderTable = ({ rideOrders, tableId, onCancel, onEdit, emptyState, onAddReturnRide, timeoutRef, tableRowRefs, scrolledToRowId, setScrolledToRowId}) => {
  const scrollToRow = (id) => {
    const element = tableRowRefs?.current[id];
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setScrolledToRowId(id);
      timeoutRef.current = setTimeout(() => {
        setScrolledToRowId(null);
      }, 2000);
    }
  };
  
  const buildRideTypeCell = (order) => (
      <IconContainer inline={true}
                     label={order.rideType?.description}
                     icon={<CmRideTypeIcon value={order.rideType}/>}
                     size={ICON_SIZES.lg}
      />
  )

  const buildDateTimeCell = (order) => {
    const renderTime = (icon, text, time, secondary) => (
      time.localizedTime && (
        <div className={`date-time-container ${secondary ? "secondary" : ""}`}>
          <span>{icon}</span>
          <span><span className="date-time-text">{text}</span>
          {time.localizedTime}</span>
        </div>
      )
    );
  
    return (
      <span>
        <div>
          { order.dateOfRide.formatRelativeDay()}, { order.dateOfRide.localizedDate }
        </div>
        { order.pickupTime || order.endLocationArrivalTime ? (
          <>
            {order.pickupTime && renderTime(<FaCarOn />, i18n.t("pickUp"), order.pickupTime)}
            {order.endLocationArrivalTime && renderTime(<FaFlagCheckered />, i18n.t("arrival"), order.endLocationArrivalTime, !!order.pickupTime)}
          </>
        ) : 
        (
          <>
          { renderTime(order.isArrivalTime ? <FaFlagCheckered /> : <FaCarOn />, order.rideTimingType, order.timeOfRide)}
          </>
        )}
        { order.rideOrderType && 
          <Warning order={order} />
        }
      </span>
    );
    
  };

  const buildLocationCell = (place) => {
    if (!place) {
      return null;
    }

    return (
        <span>
            <em>{place.description}</em><br/>
            <span>{place.street}{(place.street && (place.zip || place.city)) ? ", " : null}</span>
            <span>{place.zip} {place.city}</span>
          </span>
    )
  }

  const buildPassengerCell = (order) => (
      <em>
        {order.lastName}
        {(order.firstName && order.lastName) ? ", " : null}
        {order.firstName}
      </em>
  )

  const buildStateCell = (order) => (
      <div>
        <OrderStateBadge state={order.rideOrderState}/>
      </div>
  )

  const buildMessageCell = (order) => (
      <div className={""}>
        {order.message ?
            <SectionMessage title={`${i18n.t("reply")}:`}
                            message={order.message}
                            variant={order.rideOrderState.value === RideOrderState.REJECTED ?
                                SECTION_MESSAGE_VARIANTS.warning :
                                SECTION_MESSAGE_VARIANTS.info}
                            appearance={SECTION_MESSAGE_APPEARANCES.normal}
                            icon={<MdSupportAgent/>}
            /> : null
        }
      </div>
  )

  const buildActionsCell = (order) => {
    const disabled = [
      RideOrderState.CANCELED,
      RideOrderState.EN_ROUTE,
      RideOrderState.ARRIVED_AT_PICKUP_LOCATION,
      RideOrderState.PICKUP_COMPLETE,
      RideOrderState.COMPLETED,
    ].includes(order.rideOrderState.value)

    return (
      <ButtonGroup>
        {order.rideOrderType ? (
          <IconButton
            size={ICON_SIZES.lg}
            id="button_reference-ride"
            title={order.rideOrderType === RideOrderType.OUTWARD ? i18n.t("scroll to return ride") : i18n.t("scroll to outward ride")}
            className="reference-ride-button"
            icon={order.rideOrderType === RideOrderType.OUTWARD ? <BsArrowUpRightCircle /> : <BsArrowDownLeftCircle />}
            onClick={() => {
              scrollToRow(order.referencedRideOrderId);
            }}
          />
        ) : (
          <IconButton
            size={ICON_SIZES.lg}
            id="button_add-return-ride"
            title={i18n.t("order return ride")}
            icon={<RiArrowGoBackFill />}
            disabled={[
              RideOrderState.CANCELED,
              RideOrderState.REJECTED,
            ].includes(order.rideOrderState.value)}
            onClick={() => {
              onAddReturnRide(order);
            }}
          />
        )}


        <IconButton size={ICON_SIZES.lg}
                    id="button_edit-ride"
                    title={i18n.t("transportEdit")}
                    icon={<MdEdit/>}
                    disabled={disabled}
                    onClick={() => {
                      onEdit(order)
                    }}
        />
        <IconButton size={ICON_SIZES.lg}
                    id="button_cancel-ride"
                    title={i18n.t("transportCancel")}
                    icon={<MdOutlineCancel/>}
                    disabled={disabled}
                    onClick={() => {
                      onCancel(order)
                    }}
        />
      </ButtonGroup>
    )
  }

  const buildOrderTableRow = (order, index) => {
    return <Fragment>
      <StyledTableRow className={(scrolledToRowId === order.rideOrderId ? "blink" : "")} id={"styledtablerow_order-row-" + index}>
        <td ref={tableRowRefs ? (element => tableRowRefs.current[order.rideOrderId] = element) : null} id="cell_passenger">{buildPassengerCell(order)}</td>
        <td id="cell_state">{buildStateCell(order)}</td>
        <td id="cell_date-time">{buildDateTimeCell(order)}</td>
        <td id="cell_ride-type">{buildRideTypeCell(order)}</td>
        <td id="cell_start-location">{buildLocationCell(order.startLocation)}</td>
        <td id="cell_end-location">{buildLocationCell(order.endLocation)}</td>
        <td id="cell_actions">{buildActionsCell(order)}</td>
      </StyledTableRow>
      {order.message ? <StyledTableRow className={"message-row"}>
        <td id="cell_message" colSpan={7}>{buildMessageCell(order)}</td>
      </StyledTableRow> : null}
    </Fragment>
  }

  const headerNames = [
    i18n.t("passenger"),
    i18n.t("state"),
    i18n.t("pointInTime"),
    i18n.t("rideType"),
    i18n.t("tripOrigin"),
    i18n.t("tripDestination"),
    i18n.t("actions"),
  ]

  if (rideOrders.length > 0) {
    return (
        <div className={"ride-order-table"}>
          <StyledTable tableId={tableId} headerNames={headerNames}>
            {rideOrders.map(buildOrderTableRow)}
          </StyledTable>
        </div>

    )
  } else {
    return emptyState ? emptyState : <EmptyState title={i18n.t("no orders title")}
                                                 description={i18n.t("no orders description")}
                                                 icon={<MdDone/>}
    />;
  }
}

export default RideOrderTable;