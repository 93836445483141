import React, {useContext, useEffect} from "react";
import {Route, Routes} from "react-router-dom";
import {adminRideProvider, adminRideProviders, home, rideOrders, routeParameter} from "../core/constants/routes";
import RideOrdersPage from "../pages/RideOrdersPage";
import RideProvidersPage from "../pages/RideProvidersPage";
import RideOrdererPage from "../pages/RideOrdererPage";
import {AppContainer, Navbar, Spinner} from "@odm/ui";
import {useSelector} from "react-redux";
import {localUserSelector} from "../core/redux/slices/localUserSlice";
import {keycloak} from "../auth/KeycloakAuthProvider";
import {ThemeContext} from "./ThemeContextProvider";
import NotFoundPage from "../pages/NotFoundPage";
import RideProviderPage from "../pages/RideProviderPage";
import useDispatchAsync from "../core/hooks/useDispatchAsync";
import {getRideProvider, selectRideProviderById} from "../core/redux/slices/rideProviderSlice";
import RideProviderCustomizationPage from "../pages/RideProviderCustomizationPage";
import {getRideTypes} from "../core/redux/slices/rideTypeSlice";
import ProtectedContent from "./ProtectedContent";
import {UserRole} from "../core/models/UserRole";
import RideProviderOrganizationPage from "../pages/RideProviderOrganizationPage";
import i18n from "../core/constants/i18n";
import {Helmet} from "react-helmet";
import {FaBell, FaBellSlash} from "react-icons/fa";
import useWebNotifications from "../core/hooks/useWebNotifications";
import {edit, selectCurrentLocalUser} from "../core/redux/slices/userSlice";
import {MdOutlineMailOutline, MdOutlineMarkEmailRead} from "react-icons/md";

const WebOrderContent = () => {

  const theme = useContext(ThemeContext)
  const localUser = useSelector(localUserSelector);
  const user = useSelector(selectCurrentLocalUser);
  const {rideProviderId} = localUser;

  const [dispatchGetRideProvider] = useDispatchAsync(getRideProvider);
  const [dispatchGetRideTypes] = useDispatchAsync(getRideTypes);
  const [dispatchEditUser] = useDispatchAsync(edit)
  const rideProvider = useSelector((state) => selectRideProviderById(state, rideProviderId));


  const {
    pushNotificationsAvailable,
    subscribed,
    handleAllowNotificationsAsync,
    handleUnsubscribeAsync,
    loadingPermission,
  } = useWebNotifications();

  useEffect(() => {
    if (!rideProviderId) {
      return;
    }

    dispatchGetRideProvider({rideProviderId});
    dispatchGetRideTypes({rideProviderId})
  }, [rideProviderId])

  const userIsNotAdmin = !localUser?.hasAtLeastRole(UserRole.ADMIN);

  const toggleEmailNotifications = () => {
    dispatchEditUser({...user.payload(), emailNotificationsEnabled: !user.emailNotificationsEnabled})
  }

  const buildContentView = () => {
    return <Routes>

      { /* User-accessible Routes */}

      <Route path={home} element={<RideOrdersPage/>}/>
      <Route path={rideOrders} element={<RideOrdersPage/>}/>

      { /* Admin-accessible Routes */}

      <Route path={adminRideProvider} element={
        <ProtectedContent minimumRole={UserRole.ADMIN}>
          <RideProviderPage/>
        </ProtectedContent>}>

        <Route index element={
          <ProtectedContent minimumRole={UserRole.ADMIN}>
            <RideProviderOrganizationPage/>
          </ProtectedContent>
        }/>

        <Route path={`:page-info`} element={
          <ProtectedContent minimumRole={UserRole.ADMIN}>
            <RideProviderCustomizationPage/>
          </ProtectedContent>
        }/>

        <Route path={`:${routeParameter.rideOrdererId}`} element={
          <ProtectedContent minimumRole={UserRole.ADMIN}>
            <RideOrdererPage/>
          </ProtectedContent>}
        />

      </Route>

      { /* Super-Admin-accessible Routes */}

      <Route path={adminRideProviders} element={
        <ProtectedContent minimumRole={UserRole.SUPER_ADMIN}>
          <RideProvidersPage/>
        </ProtectedContent>}
      />

      { /* Anonymous Routes */}

      <Route path="*" element={<NotFoundPage/>}/>
    </Routes>
  }

  const buildMenuItems = () => {
    const menuItems = [];
    if(userIsNotAdmin) {
      if(pushNotificationsAvailable) {
        const webNotifications = {
          label: i18n.t(loadingPermission ? "enabling notifications" :
            subscribed ?
              "disable browser notifications" :
              "enable browser notifications"),

          icon: loadingPermission
            ? <Spinner/>
            : subscribed ? <FaBellSlash/> : <FaBell/>,

          onClick:
            loadingPermission ? null : () => {
              if (subscribed) {
                handleUnsubscribeAsync();
              } else {
                handleAllowNotificationsAsync();
              }
            },
          id: "menu-item_notifications"
        }
        menuItems.push(webNotifications);
      }
      if(user){
        const emailNotifications = {
          label: i18n.t(user.emailNotificationsEnabled
            ? "disable email notifications"
            : "enable email notifications"),
          icon: user.emailNotificationsEnabled
            ? <MdOutlineMarkEmailRead />
            : <MdOutlineMailOutline  />,
          onClick: toggleEmailNotifications,
          id: "menu-item_email_notifications"
        }

        menuItems.push(emailNotifications)
      }
    }

    return menuItems
  }


  return (
      <AppContainer navbar={
        <Navbar logoSource={rideProvider?.pageInformation?.logoImageUrl || theme.logo}
                logoId="link_navbar-logo"
                userMenuProps={{
                  userMenuButtonId: "button_user-menu",
                  user: localUser,
                  logOutButtonId: "menu-item_log-out",
                  onLogOut: () => keycloak.logout(),
                  menuItems: buildMenuItems()
                }}
        />
      }>
        <Helmet>
          <title>{i18n.t("admin page title")}</title>
        </Helmet>
        {buildContentView()}
      </AppContainer>
  )
}

export default WebOrderContent;